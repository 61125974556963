export const batchLink = async (task_ids, wsid, recursive) => {
    // Note: task_ids is expected to be a comma-separated string of task IDs, not a array
    let url = getUrl(`/api/tasks/batchLink/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: task_ids,
            wsid: wsid,
            recursive: recursive
        }
    });
    return result;
}